import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImageWithCenteredContent";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground";
import MainFeature from "components/features/SixFeaturesWithBackground";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview";
import Clients from "components/cards/AutoSlider";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/FiveColumnDark";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

export default () => (
    <AnimationRevealPage>
        <Hero />
        <MainFeature />
        <Features />
        <Clients />
        {/* <Portfolio /> */}
        <Blog />
        <Footer />
    </AnimationRevealPage>
);
