import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import RnDIconImage from "feather-icons/dist/icons/cpu.svg";
import SystemIntegrationImage from "feather-icons/dist/icons/settings.svg";
import CourseIconImage from "feather-icons/dist/icons/book-open.svg";
import ConsultingIconImage from "feather-icons/dist/icons/award.svg";
import PrototypeIconImage from "feather-icons/dist/icons/box.svg";
import TestingIconImage from "feather-icons/dist/icons/check-circle.svg";

import BcImage from "images/expertise/aiot.jpg";

const Container = styled.div(props => [
  tw`relative -mx-8 px-8 text-gray-100`,
  `background: url("${props.imageSrc}") no-repeat center center;
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8); // Change this to the color and opacity you want
    z-index: 1;
  }
`]);

const Content = styled.div`
  ${tw`relative z-10`}
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Our Services",
  subheading = "",
  description = "Our services encompass a broad range of solutions, from innovative research and development to cutting-edge tech consulting."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: RnDIconImage,
      title: "Research and Development",
      description: "Our R&D service offers innovative solutions and consulting to drive product innovation and business growth. We leverage cutting-edge tech and market insights to guide clients through challenges and opportunities, ensuring tangible results and sustainable success."
    },
    {
      imageSrc: SystemIntegrationImage,
      title: "System Integration",
      description: "We specialize in seamless system integration, ensuring smooth communication and interoperability across various technologies. Our tailored solutions guarantee efficiency and reliability for IoT, automotive, control, and industrial systems, driving business success through seamless integration."
    },
    {
      imageSrc: CourseIconImage,
      title: "Course and Skill Development",
      description: "Our course and skill development service is designed to empower your team to thrive in the digital age. Tailored training programs and online courses cover a wide range of topics, from embedded systems to software development. Our experienced instructors provide hands-on guidance, ensuring your team gains valuable skills applicable to their work."
    },
    {
      imageSrc: ConsultingIconImage,
      title: "Technology Consulting",
      description: "Provide expert guidance to help you use technology effectively for your business goals. From assessing your current setup to crafting customized solutions, our experienced consultants work closely with you to find ways to improve and innovate."
    },
    {
      imageSrc: PrototypeIconImage,
      title: "Rapid Prototyping and Sourcing",
      description: "We specialize in transforming concepts into tangible prototypes, leveraging our expertise in sourcing high-quality materials and components. With our streamlined sourcing process, we ensure timely access to the resources you need, enabling you to move faster in your product development journey."
    },
    {
      imageSrc: TestingIconImage,
      title: "Product Testing",
      description: "Leverage our expertise to ensure the quality and reliability of your products through rigorous field testing and quality assurance measures. We employs advanced testing methodologies to provide actionable insights and recommendations, for example wireless coverage testing, EMI/EMC, A/B testing, and more."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container imageSrc={BcImage}>
      <Content>
        <ThreeColumnContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          <VerticalSpacer />
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                  <p className="description">
                    {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                  </p>
                </span>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </Content>
    </Container >
  );
};
