import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import MCUProgramming from "../../images/expertise/mcu.jpg";
import SystemSoftwareDesign from "../../images/expertise/systemsoftware.jpg";
import AIOTIntegration from "../../images/expertise/aiot.jpg";
import EmbeddedLinux from "../../images/expertise/embedded-linux.jpg";
import PCBDesign from "../../images/expertise/pcb.jpg";
import WebDesign from "../../images/expertise/web.jpg";
import DataAnalytics from "../../images/expertise/data.jpg";
import AIIntegration from "../../images/expertise/ai.jpg";
import Infrastructure from "../../images/expertise/network.jpg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full mb-10`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = tw.div`relative overflow-hidden mb-4`;
const CardImage = tw.img`w-full h-64 object-cover`;
const CardOverlay = tw.div`absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center`;
const CardText = tw.div`text-center text-white p-5`;
const CardHeading = tw.h2`text-3xl font-bold mb-2`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: MCUProgramming,
      title: "Microcontroller and RTOS Programming",
      description: "Develop efficient, real-time solutions for embedded systems, ensuring optimal performance and reliability."
    },
    {
      imageSrc: SystemSoftwareDesign,
      title: "System and Software Design",
      description: "Crafting robust architectures and high-quality code to build scalable and reliable software solutions."
    },
    {
      imageSrc: EmbeddedLinux,
      title: "Embedded Linux Customization",
      description: "Customize and optimize Linux-based systems for embedded applications, ensuring seamless performance and reliability."
    },
    {
      imageSrc: PCBDesign,
      title: "PCB and Mechanical Design",
      description: "Design and develop custom PCBs and mechanical components to meet your unique requirements."
    },
    {
      imageSrc: WebDesign,
      title: "Web and Mobile Applications Development",
      description: "Create user-friendly web and mobile applications that deliver exceptional user experiences and drive business growth."
    },
    {
      imageSrc: DataAnalytics,
      title: "Data and Business Analytics with AI",
      description: "Leverage data analytics to gain valuable insights, optimize operations, and drive informed decision-making."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Expertise</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <CardImage src={card.imageSrc || defaultCardImage} />
              <CardOverlay>
                <CardText>
                  <CardHeading>{card.title}</CardHeading>
                  {/* <p>{card.description}</p> */}
                </CardText>
              </CardOverlay>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container >
  );
};
