import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, NavToggle, DesktopNavLinks } from "../headers/light.js";
import { TypeAnimation } from "react-type-animation";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink} {
    ${tw`text-gray-100`}
    &:hover {
      ${tw`border-gray-300 text-gray-300`} 
    }
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2 text-secondary-200`} 
  }
`;

const Subtitle = styled.p`
  ${tw`text-center text-gray-100 font-medium mt-4 max-w-3xl`}
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const SecondaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold text-gray-100 border-2 border-gray-100 shadow transition duration-300 hocus:bg-gray-100 hocus:text-primary-500 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">
        About
      </NavLink>
      <NavLink href="#">
        Blog
      </NavLink>
      <NavLink href="#">
        Service
      </NavLink>
      <NavLink href="#">
        Course
      </NavLink>
      <NavLink href="#">
        Store
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="#">Contact Us</PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
            Your Premier Partner in
            <br />
            <TypeAnimation
              sequence={[
                'Embedded Systems Development',
                1000, // Waits 1s
                'IoT Solutions and Services',
                1000,
                'Custom Software Development',
                1000
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
            />
          </Heading>
          <Subtitle>Garetek isn’t just another tech company;
            we’re your strategic ally in overcoming business challenges through customized software and hardware solutions.
            With a relentless focus on addressing your specific needs,
            we deliver tailored-made innovations that propel your business forward.
            We value delivery accuracy and precison beacause every single bit matters.</Subtitle>
          <div style={{ display: "flex", gap: "1rem" }}>
            <PrimaryAction>Services</PrimaryAction>
            <SecondaryAction>About Us</SecondaryAction>
          </div>
        </Content>
      </HeroContainer>
    </Container>
  );
};
